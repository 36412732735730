import React from "react";
import "./ContactMessage.scss";
export default function MessageItem(props) {
  return (
    <tr key={props.key}>
      <td className="table-data">{props.dateSent}</td>
      <td className="table-data">{props.name}</td>
      <td className="table-data">{props.email}</td>
      <td className="table-data client-message">{props.message}</td>
      <td className="table-data">
        {/* <button className="action-button">
          <i className="fas fa-lock"></i>
        </button>
        <button className="action-button">
          <i className="fas fa-unlock"></i>
        </button> */}
        <button className="action-button delete hvr-grow" onClick={()=> props.handleDeleteMessage(props.id)}>
         Delete <i className="fas fa-trash-alt"></i>
        </button>
      </td>
    </tr>
  );
}
