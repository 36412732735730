// add data to the database
import { db } from "./firebase";
import _ from "lodash";

const addData = (values) => {
  const messageData = _.cloneDeep(values);
  const date = new Date();
  const name = messageData.name;
  const email = messageData.email;
  const message = messageData.message;
  return db.collection("users").add({
    name: name,
    email: email,
    message: message,
    dateSent: date,
  });
};

export default addData;
