import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import addData from "../../helpers";
import "./Contact.scss";

export default function Contact() {
  const [values, setValues] = useState({});
  const [button, setButton] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (values.name && values.email && values.message) {
      setButton(false);
      addData(values);
    } else {
      alert("Please fill out all fields.");
    }
  };

  const generateRandomNumber = () => {
    return Math.round(Math.random() * Math.floor(9));
  };
  const showButton = () => {
    if (!button) {
      return (
        <section className="thankyou-section">
          <div className="message-container">
            <Fade left>
              <p className="submitted">
                Your{" "}
                <span className="lucky-number">
                  &nbsp; today's lucky number
                </span>{" "}
                is:
              </p>
            </Fade>
            <Fade right>
              <p className="submitted number">{generateRandomNumber()}</p>
            </Fade>
          </div>
          <div className="section-line"></div>
          <div className="message-container">
            <Fade left>
              <p className="submitted">
                <i class="far fa-thumbs-up" />
              </p>
            </Fade>
            <Fade right>
              <h4 className="submitted">Thanks for reaching out!</h4>
              <p className="thank-you">
                I will get back to you within 1-2 business days. <br />
                For urgent inquires, please call me.{" "}
              </p>
            </Fade>
            <Fade top>
              <p className="cheers">Cheers, Parth Desai</p>
            </Fade>
          </div>
        </section>
      );
    } else if (button) {
      return (
        <section className="input-section">
          <Fade top>
            <p className="thank-you">
              Send me a message to reveal your lucky number of the day!
            </p>
          </Fade>
          <form name="contact-form" className="contact-form">
            <div className="name-email">
              <Fade bottom>
                <input
                  className="client-name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                  onChange={handleInputChange}
                />
              </Fade>
              <Fade bottom>
                <input
                  className="client-name"
                  type="e-mail"
                  name="email"
                  required
                  placeholder="E-mail"
                  onChange={handleInputChange}
                />
              </Fade>
            </div>
            <Fade left>
              <textarea
                name="message"
                className="message"
                required
                placeholder="How can I Help you?"
                onChange={handleInputChange}
              />
            </Fade>
            <Fade bottom>
              {" "}
              <button
                type="submit"
                id="button"
                className="form-button"
                onClick={handleSubmit}
              >
                send message
              </button>
            </Fade>
          </form>
        </section>
      );
    }
  };

  return (
    <main className="main-page">
      <div className="wrapper">
        <Fade top>
          <h2 className="page-heading">Get in Touch</h2>
        </Fade>
        {showButton()}
        <footer className="contact-footer">
          <Fade top>
            <div className="contact-social">
              <i className="fas fa-envelope-open" />
              <p className="contact-type">parthjd.tech@gmail.com</p>
            </div>
          </Fade>
          <Fade bottom>
            <div className="contact-social">
              <i className="fas fa-map-marker-alt" />
              <p className="contact-type">Edmonton, AB</p>
            </div>
          </Fade>
          <Fade top>
            <div className="contact-social">
              <i className="fas fa-phone-alt" />
              <p className="contact-type">(+1) 647 - 532 - 0880 </p>
            </div>
          </Fade>
        </footer>
      </div>
      <p className="copyright">2022 © Parth Desai. All rights reserved.</p>
    </main>
  );
}
