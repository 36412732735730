import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
require("dotenv").config();
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: "https://portfolio-274622.firebaseio.com",
  projectId: "portfolio-274622",
  storageBucket: "portfolio-274622.appspot.com",
  messagingSenderId: "821093665919",
  appId: "1:821093665919:web:d612ae01e3c36e36d4834a",
  measurementId: "G-N33RQF9PY1",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export { db, firebase };
