import React, { useEffect, useState } from "react";

import { db } from "../../firebase";
import Fade from "react-reveal/Fade";
import MessageItem from "./MessageItem";

import "./ContactMessage.scss";
export default function ContactMessage() {
  const [messages, setMessages] = useState([]);

  const getData = () => {
    return db
      .collection("users")
      .onSnapshot((message) => {
        const messages = message.docs.map((doc) => { return { ...doc.data(), id: doc.id } });
        setMessages(messages);
      },
      ((err) => console.log("Error getting messages.", err))
      
    )
  };

  const handleDeleteMessage = (id) => {
      return db.collection("users").doc(id).delete()
  }

  useEffect(() => {
    getData();
  }, []);

  const getToday = (timestamp) => {
    const newTimestamp =
      timestamp && timestamp.seconds ? timestamp.seconds * 1000 : timestamp;
    const newDate = new Date(newTimestamp).toDateString();
    const newTime = new Date(newTimestamp).toTimeString().slice(0, 5);
    return `${newDate} at  ${newTime}`;
  };

  return (
    <main className="main-page">
      <div className="message-wrapper">
        <Fade down>
          <h2 className="page-heading"> Messages:</h2>
        </Fade>
        <div className="messages-container">
          <table className="message-table">
            <thead>
              <tr>
                <th className="table-heading date">Date Sent</th>
                <th className="table-heading name">Client Name</th>
                <th className="table-heading email">Client Email</th>
                <th className="table-heading message-header">Client Message</th>
                <th className="table-heading actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {messages.sort((a, b) => {
      return (
        (b.dateSent.seconds ? b.dateSent.seconds : b.dateSent)-
        (a.dateSent.seconds ? a.dateSent.seconds : a.dateSent) 
      );
    }).map((message) => (
                
                <MessageItem
                  key={message.id}
                  id={message.id}
                  dateSent={getToday(message.dateSent)}
                  name={message.name}
                  email={message.email}
                  message={message.message}
                  handleDeleteMessage={handleDeleteMessage}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
